.wifiBatteryContainer {
  width: 155px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 0 4px;
  .removeIconWrapper {
    cursor: pointer;
    width: 30px;
  }
  .wifiIconWrapper {
    width: 25px;
    margin-left: 50px;
  }
  .batteryIconContainer {
    margin-right: 8px;
  }
  .batteryStatusWrapper {
    display: none;
    position: absolute;
    bottom: 245px;
    right: -20px;
  }
  .batteryIconContainer:hover ~ .batteryStatusWrapper {
    display: block;
  }
  .wifiStatusWrapper {
    display: none;
    position: absolute;
    bottom: 245px;
    right: 10px;
  }
  .wifiIconWrapper:hover ~ .wifiStatusWrapper {
    display: block;
  }
}

.miniWifiBatteryContainer {
  padding: 0 12px 0 12px;
  .removeIconWrapper {
    cursor: pointer;
    width: 20px;
    margin-left: 8px;
    padding-top: 3px;

    img {
      width: 100%;
    }
  }
  .batteryIconContainer {
    margin-right: 8px;
  }
  .batteryStatusWrapper {
    bottom: 55px;
    right: -10px;
  }
  .wifiStatusWrapper {
    bottom: 55px;
    right: 40px;
  }
  .wifiIconWrapper {
    margin-left: 0;
    border-left: 1px solid white;
    padding-left: 13px;
    padding-right: 13px;
    width: 32px;
    border-right: 1px solid white;
    height: 77%;
  }
}

.miniLocalContainer {
  height: 50px !important;
  bottom: 16px;
  border-radius: 30px;
  padding: 0 8px;
}
