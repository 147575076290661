.navigation-video {
	position: absolute;
	bottom: 0px;
	background-color: black;
}

.navigation-video .loading-indicator-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.navigation-video .loading-indicator {
	border: 3px solid #a5a5a6;
	border-top: 3px solid #1e1f22;
	border-bottom: 3px solid #1e1f22;
	border-right: 3px solid #1e1f22;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

.navigation-video video {
	object-fit: contain;
	object-position: 50% 50%;
	background-color: #a5a5a6;
	position: absolute;
}

.navigation-video .joystick-container {
	position: absolute;
}

.greyVideo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}