.sessionIdContainer {
	color: #ffffff;
	text-shadow: 0px 2px 10px #00000029;
	position: absolute;
	top: 16px;
	right: 16px;
	display: flex;
	font-family: Roboto;
	opacity: 0.5;
	cursor: default;
	span {
		padding-top: 3px;
	}
}
.sessionIdContainer:hover {
	opacity: 1;
}
