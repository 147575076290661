@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto&display=swap');
@import url("https://use.typekit.net/npb6iaz.css");

body {
	outline: none;
	font-family: 'Roboto', sans-serif;
	overflow: hidden;
	background-color: #1e1f22;
}


.containerMenuCollapsed{
	left: 110px !important;
}

.containerMenuExpanded{
	left: 290px !important;
}

.displayNone{
	display: none;
}

.icon{
	width: 25px;
    height: 25px;
}