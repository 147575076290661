.sessionOptionsContainer {
  width: 50px;
  height: 240px;
  position: absolute;
  bottom: -60px;
  display: flex;
  flex-direction: column;
  .roundedButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #1e1f22;
    opacity: 0.9;
    transition: 0.5s;
    margin-bottom: 10px;
    cursor: pointer;
    &.red {
      background-color: #d92e2e !important;
    }
    &.gray {
      background: #aaaaaa;
    }
    &.disabled {
      width: 50px !important;
      opacity: 0.5;
      transition: 0.5s;
    }
    &.rectangle {
      .rectangleLocalVoice {
        transition: 0.5s;
        width: 0px;
        overflow: hidden;
      }
      .localVoiceText {
        overflow: hidden;
        display: none;
      }
    }
    &.rectangle:hover {
      width: 225px;
      opacity: 0.9;
      border-radius: 50px;
      transition: 0.5s;
      display: flex;
      justify-content: space-between;
      padding: 0 16px 0 12px;
      &.navSpeedRectangle {
        width: 250px;
      }
      .rectangleLocalVoice {
        width: 150px;
        height: 50px;
        opacity: 1 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        transition: 0.5s;
      }
      .localVoiceText {
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #56ae4d;
        display: block;
      }
    }
  }
  .roundedButton:hover {
    opacity: 0.9;
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
  }
  .remoteVoiceWrapper {
    position: absolute;
    left: 120px;
  }
}

.speedControllerContainer {
  display: flex;
  align-items: center;
  color: #56ae4d;
  font-size: 12px;
  margin-right: 10px;
}

.indicatorContainer {
  .indicatorsWrapper {
    display: none;
    width: 150px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 13px;
    .greyIndicator {
      width: 4px;
      height: 3px;
      border-radius: 2px;
      background-color: #8e8c89;
    }
    .yellowIndicator {
      background-color: #df760d !important;
    }
    .redIndicator {
      background-color: red !important;
    }
    .greenIndicator {
      background-color: #56ae4d !important;
    }
  }
}
