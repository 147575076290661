.localContainer {
  width: 170px;
  height: 240px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  bottom: 92px;
  left: calc(100% - 220px);
  z-index: 100;
  background-color: #1e1f22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1000;
  .localVideo {
    object-fit: cover;
    width: 164px;
    height: 200px;
    margin: auto;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
    pointer-events: none;
  }
  .hideLocalVideo {
    display: none;
  }
  .showLocalLoading {
    background-color: #a5a5a6;
    opacity: 0.5;
    width: 164px;
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .localLoading {
      border: 3px solid #a5a5a6;
      border-top: 3px solid #1e1f22;
      border-bottom: 3px solid #1e1f22;
      border-right: 3px solid #1e1f22;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .whiteLogoWrapper {
    position: absolute;
    width: 50px;
    top: 50px;
    right: 10px;
  }
  .audioRecognizeContainer {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .audioStrength {
      background-color: #56ae4d;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    }
  }
}

.localInputContainer {
  width: 230px;
  height: 80px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  top: calc(100% - 96px);
  left: calc(100% - 246px);
  z-index: 100;
  background-color: #1e1f22;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 15px;
  input {
    background-color: #1e1f22;
    border: none;
    outline: none;
    color: white;
    text-align: center;
    font-family: Industry;
    font-size: 13px;
  }
}

.greyVideo {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
