.robotNameContainer {
  color: #ffffff;
  text-shadow: 0px 2px 10px #00000029;
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  font-family: Roboto;
  opacity: 0.5;
  cursor: default;
  padding: 5px;

  .robotHeadWrapper {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  span {
    padding-top: 3px;
  }
  .name{
	margin-right: 8px;
  }
}

.robotNameContainer:hover {
  opacity: 1;
}

.robotNameContainerDrivingMode {
  background-color: #1e1f22;
  border-radius: 10px;
}


.timer{
	margin-left: 8px;
}