.remoteVideo {
  width: 112% !important;
  height: 100%;
  position: absolute;
  left: -6%;
  position: absolute;
  object-fit: cover;
  transform: rotate(180deg);
  object-position: 2px 0%;
  pointer-events: none;
}

.sessionStartContainer {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
  background-color: #1e1f22;
  position: absolute;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 15px;
}

.sessionStartLoading {
  border: 8px solid #1e1f22;
  border-top: 8px solid white;
  border-bottom: 8px solid white;
  border-right: 8px solid white;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 1%;
  animation: spin 2s linear infinite;
}
.sessionStartText {
  margin-bottom: 15%;
}

.greyVideo {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fullScreenToggleButton {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.15);
  top: 16px;
  left: 16px;
  background-color: #ffffff;
  opacity: 0.5;
  img {
    color: black;
    opacity: 0.5;
    font-size: 25px;
  }
}
.fullScreenToggleButton:hover {
  opacity: 0.8;
  img {
    opacity: 0.8;
  }
}
