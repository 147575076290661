.dynamic-media {
  width: 100%;
  position: relative !important;
}

.dynamic-media .resize-container-inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dynamic-media .media-cropping-box {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.dynamic-media video {
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 0px;
  box-sizing: content-box;
  background-color: black;
}