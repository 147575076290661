.retrying-session-container {
	width: 100%;
	height: 100vh;
	opacity: 0.95;
	background-color: #1e1f22;
	position: absolute;
	z-index: 10000;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	h1 {
		font-family: Industry;
		font-size: 35px;
	}

	.loading-indicator {
		border: 8px solid transparent;
		border-top: 8px solid white;
		border-bottom: 8px solid white;
		border-right: 8px solid white;
		border-radius: 50%;
		width: 110px;
		height: 110px;
		animation: spin 2s linear infinite;
	  }

	.title {
		font-family: Industry;
		font-size: 40px;
		line-height: 52px;
		margin-top: 16px;
	}
	.message {
		font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-size: 17px;
		line-height: 24px;
		margin: 32px 0px 32px 0px;
	}
	.end-session-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 150px;
		padding: 14px 32px;
		border-radius: 6px;
		background-color: #D92E2E;
		cursor: pointer;
	}
}
