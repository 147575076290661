.drivingImpairmentContainer {
	width: 470px;
	top: calc(100vh - 275px);
	left: 30px;
	opacity: 0.95;
	background-color: #1e1f22;
	position: absolute;
	z-index: 100;
	justify-content: center;
	display: flex;
	color: white;
	border-radius: 10px;
	padding: 24px;
	.contentContainer {
		width: 97%;
		.headingContainer {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			text-align: center;
			font-size: 24px;
			.impairedHeading {
				color: #ffffff;
				text-align: center;
				font-family: Industry;
				letter-spacing: 0px;
				color: #ffffff;
				opacity: 1;
				padding-top: 6px;
			}
			.warningIconWrapper {
				width: 24px;
				height: 24px;
				margin-right: 8px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
		.textSessionConnectionContainer {
			text-align: left;
			width: 100%;
			padding-top: 8px;
		}
	}
	.closeIconWrapper {
		width: 3%;
		display: flex;
		justify-content: center;
		img {
			cursor: pointer;
		}
	}
}

.drivingImpairmentContainer:hover {
	display: flex !important;
}

.drivingImpairmentCircle {
	top: calc(100vh - 175px);
	left: 30px;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	opacity: 0.5;
	background-color: #1e1f22;
	position: absolute;
	z-index: 100;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	.warningIconWrapper {
		width: 25px;
		height: 25px;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.drivingImpairmentCircle:hover {
	display: flex !important;
}

.drivingImpairmentCircle:hover {
	opacity: 0.95;
}
