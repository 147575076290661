.zoomTransitionContainer {
	width: 100%;
	height: 100%;
	opacity: 0.9;
	background-color: #1e1f22;
	position: absolute;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 15px;
}

.zoomTransitionLoading {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 8px solid #1e1f22;
	border-top: 8px solid white;
	border-bottom: 8px solid white;
	border-right: 8px solid white;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	margin-bottom: 1%;
	animation: spin 2s linear infinite;
}

.zoomTransitionText {
	position: absolute;
	top: calc(50% - -102px);
    left: calc(50% - 50px);
	font-size: 18px;
}

.zoomIcon {
    position: absolute;
	top: calc(50% - 50px);
    left: calc(50% - 37px);
	height: 75px;
	width: 75px;
}
