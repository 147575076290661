.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: 16px;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #1e1f22e6;
  &.isMinimized {
    width: 20px;
    height: 50px;
    border-radius: 50px;
    opacity: 0.5;
  }
  &.isMinimized:hover {
    opacity: 0.95;
  }
  p {
    font-size: 13px;
    color: #ffffff;
    margin: 15px;
  }
  .navIcon {
    height: 28px;
    width: 26px;
    color: #56ae4d;
  }
}
