.robotFeaturesContainer {
  width: 50px;
  height: 240px;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column-reverse;
  .blackCircle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #1e1f22;
    opacity: 0.5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.9;
    margin-bottom: 10px;
    &.disabled {
      opacity: 0.5;
      transition: 0.5s;
    }
  }
  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
  }
}
